/**
 * Class Field
 */
class Field {
  /**
   * Field Name
   * @type {string}
   */
  name = "";
  /**
   * Field label
   * @type {string}
   */
  label = "";
  /**
   * Field types: "text", "number"
   * @type {string}
   */
  type = "text";
  /**
   * rules
   * Accepts a mixed array of types function, boolean and string
   * @type {[]}
   */
  rules = [];

  /**
   * Field
   * @param {String} name
   * @param {String} label
   * @param {String} type
   * @param {(function(*=): *)[]} rules
   */
  constructor(name = "", label = "", rules = [], type = "") {
    this.name = name;
    this.label = label;
    this.type = type;
    this.rules = rules;
  }
}

export { Field };
