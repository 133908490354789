<template>
  <!--
     persistent:
       You can block the closing of the v-edit-visibleEditDialog when clicked outside
       by adding the persistent prop
     large:
       Show Save & Cancel edit-visibleEditDialog buttons
   -->
  <v-edit-dialog
    v-bind="$attrs"
    v-on="$listeners"
    :return-value.sync="item[field.name]"
    large
    persistent
  >
    {{ item[field.name] }}
    <template v-slot:input>
      <layout-edit-field-dialog :icon-name="iconEdit">
        {{ field.label }}
        <template v-slot:field>
          <v-text-field
            :type="inputType"
            v-model="item[field.name]"
            :rules="field.rules"
          >
          </v-text-field>
        </template>
      </layout-edit-field-dialog>
    </template>
  </v-edit-dialog>
</template>

<script>
import { iconEdit } from "@/design/icon/iconConst";
import { Field } from "@/model/common/entities/Field";

export default {
  name: "EditFieldDialog",
  inheritAttrs: false,
  components: {
    LayoutEditFieldDialog: () =>
      import("@/components/shared/core/layout/LayoutEditFieldDialog")
  },
  data() {
    return {
      iconEdit: iconEdit
    };
  },
  props: {
    item: {
      type: Object,
      default: () => {}
    },
    field: {
      type: Field,
      default: () => new Field()
    }
  },
  computed: {
    inputType() {
      return this.field ? this.field.type : "text";
    }
  }
};
</script>
